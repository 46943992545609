<template>
  <ag-grid-vue
    style="width: 100%; height: 400px;"
    class="ag-theme-alpine"
    :gridOptions="gridOptions"
    @grid-ready="onGridReady"
    :columnDefs="columnDefs"
    :rowData="rowData"
    :rowSelection="rowSelection"
    :setQuickFilter="updateSearchQuery"
    suppressDragLeaveHidesColumns
    @selection-changed="onSelectionChanged"
    suppressMovableColumns
    checkboxSelection="true"
  >
  </ag-grid-vue>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import moment from "moment";
import { mapGetters } from "vuex";

import { toShowAccountingPrice } from "../../../util/accountingPrice";

export default {
  emits: ["selected-assets"],
  data() {
    return {
      columnDefs: null,
      rowSelection: "multiple",
      gridApi: "sizeColumnsToFit",
      gridOptions: {
        rowHeight: 50,
        defaultColDef: {
          resizable: true,
        },
      },
    };
  },
  props: {
    page: { type: Number, default: 1 },
    assetData: { default: () => [] },
    searchText: {
      type: String,
    },
    startMonth: {
      required: false,
      type: Number,
      default: 1,
    },
    perPage: { type: Number, default: 100 },
  },
  beforeMount() {},
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.loopHeaderFreeColumn();
  },
  computed: {
    ...mapGetters({
      isRoleLower: "isRoleLower",
      permissionStatus: "permissionStatus",
    }),

    rowData() {
      return this.assetData.map((asset, index) => {
        let dataTable = {
          index: (this.page - 1) * this.perPage + index + 1,
          note_acc: asset.note_account || "-",
          purchase_price: toShowAccountingPrice(asset.purchase_price),
          incoming_dp: toShowAccountingPrice(asset.incoming_acc_depreciation),
          january: toShowAccountingPrice(asset.jan),
          february: toShowAccountingPrice(asset.feb),
          march: toShowAccountingPrice(asset.mar),
          april: toShowAccountingPrice(asset.apr),
          may: toShowAccountingPrice(asset.may),
          june: toShowAccountingPrice(asset.jun),
          july: toShowAccountingPrice(asset.jul),
          august: toShowAccountingPrice(asset.aug),
          september: toShowAccountingPrice(asset.sep),
          october: toShowAccountingPrice(asset.oct),
          november: toShowAccountingPrice(asset.nov),
          december: toShowAccountingPrice(asset.dec),
          total_dp: toShowAccountingPrice(asset.acc_depreciation),
          residual: toShowAccountingPrice(asset.residual),
          outgoing_dp: toShowAccountingPrice(asset.outcoming_acc_depreciation),
          writeoff_acc: toShowAccountingPrice(asset.writeOffAcc),
          net_book: toShowAccountingPrice(asset.net_book),
        };
        return dataTable;
      });
    },
  },
  components: {
    AgGridVue,
  },
  watch: {
    searchText() {
      this.updateSearchQuery(this.searchText);
    },
    rowData() {
      this.loopHeaderFreeColumn();
    },
  },
  methods: {
    qrCode(asset) {
      if (this.$route.params.summaryId) {
        return asset?.qrcode;
      } else {
        return asset?.qrcode?.id_qrcode
          ? asset?.qrcode?.id_qrcode
          : this.$t("home.not_reg");
      }
    },
    sortMonth() {
      const startMonth = this.startMonth;
      const sortedMonth = [];
      let monthColumn = [
        {
          headerName: this.$t("adddate.jan"),
          field: "january",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.feb"),
          field: "february",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.mar"),
          field: "march",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.apr"),
          field: "april",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.may"),
          field: "may",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.june"),
          field: "june",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.july"),
          field: "july",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.aug"),
          field: "august",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.sep"),
          field: "september",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.oct"),
          field: "october",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.nov"),
          field: "november",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("adddate.dec"),
          field: "december",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
      ];
      if (startMonth > 1) {
        for (let i = startMonth - 1; i <= monthColumn.length - 1; i++) {
          sortedMonth.push(monthColumn[i]);
        }
        for (let i = 0; i < startMonth - 1; i++) {
          sortedMonth.push(monthColumn[i]);
        }
        return sortedMonth;
      } else {
        return monthColumn;
      }
    },
    loopHeaderFreeColumn() {
      const sortedMonthColumn = this.sortMonth();
      let columnDefs = [
        {
          headerName: this.$t("count.order"),
          field: "index",
          // headerCheckboxSelection: true,
          // headerCheckboxSelectionFilteredOnly: true,
          // checkboxSelection: true,
          maxWidth: 110,
          suppressSizeToFit: true,
          cellClass: "text-center",
        },
        {
          headerName: this.$t("home.acc_note"),
          field: "note_acc",
          sortable: true,
          minWidth: 200,
        },
        {
          headerName: this.$t("asset.purchase_price"),
          field: "purchase_price",
          sortable: true,
          minWidth: 200,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("depreciation.incoming_dp"),
          field: "incoming_dp",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        ...sortedMonthColumn,
        {
          headerName: this.$t("depreciation.total_dp"),
          field: "total_dp",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("depreciation.writeoff_total_dp"),
          field: "residual",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("depreciation.outgoing_dp"),
          field: "outgoing_dp",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("depreciation.writeoff_asset_price"),
          field: "writeoff_acc",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
        {
          headerName: this.$t("depreciation.net_book"),
          field: "net_book",
          sortable: true,
          minWidth: 150,
          suppressSizeToFit: true,
          cellClass: "text-end",
        },
      ];
      this.columnDefs = columnDefs;
    },
    onGridReady() {
      this.gridApi.sizeColumnsToFit();
    },
    momentDay(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getSelectedRowData() {
      let selectedNodes = this.gridApi.getSelectedNodes();
      let selectedData = selectedNodes.map((node) =>
        this.assetData.find(
          (asset) => asset.id_fixed_asset === node.data.id_fixed_asset
        )
      );
      return selectedData;
    },

    onSelectionChanged(event) {
      const selectedNodes = event.api.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        return node.data;
      });
      this.$emit("selected-assets", selectedData);
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
      this.gridApi.refreshCells();
    },
  },
};
</script>

<style scoped>
* >>> .ag-header-cell-label {
  justify-content: center;
}
* >>> .ag-header-row-column {
  background: transparent linear-gradient(180deg, #007afe 0%, #013f81 100%);
  color: white;
}
</style>
